.org-invite-members {
  width: 100%;
  height: 100%;
  .org-invite-code-inside {
    width: 80%;
    margin: 4rem auto;
    h1 {
      color: #000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-top: 2rem;
    }
    input {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Karla;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }

    button {
      outline: 0px;
      border: 0px;
      width: 20%;
      background-color: #5100d2;
      color: #ffffff;
      font-family: Poppins;
      margin: 1rem 0;
      padding: 1rem 0;
      border-radius: 0.625rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
