.custom-sample-template {
  width: 100%;
  height: 100%;
  display: flex;
  .cst-left {
    width: 30%;
    height: 100%;
  }
  .cst-right {
    width: 70%;
    height: 100%;
    .cst-right-inside {
      width: 80%;
      margin: 0 auto;
    }
  }
}
