.read-only-container-editor {
}

.editor-holder-reactjs-new {
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  &.active {
    display: block;
  }
  &:focus {
    outline: 0px;
  }

  // .ql-toolbar {
  //   width: 95%;
  //   border: 0px;
  //   background-color: #edf2fa;
  //   border-radius: 24px;
  //   margin: 6px 16px 8px 16px;
  //   padding: 0 8px;
  // }


  
  .ql-editor-container {
    width: 100%;
    height: 100%;

    .ql-editor {
      height: 80%;
      padding: 0 5rem;

      ol {
        list-style-type: square; /* Change the bullet style */
        margin-left: 20px; /* Adjust the left margin */
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
