.main-red {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.692);
  z-index: 99;
  .inside-main-red {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
