@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: "Gotham-Black";
  src: url("../fonts/Gotham-Black.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-BookItalic";
  src: url("../fonts/Gotham-BookItalic.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-Thin";
  src: url("../fonts/Gotham-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-ThinItalic";
  src: url("../fonts/Gotham-ThinItalic.otf") format("opentype");
}
@font-face {
  font-family: "Gotham-UltraItalic";
  src: url("../fonts/Gotham-UltraItalic.otf") format("opentype");
}

@font-face {
  font-family: "Gotham-XLight";
  src: url("../fonts/Gotham-XLight.otf") format("opentype");
}

@font-face {
  font-family: "Gotham-XLightItalic";
  src: url("../fonts/Gotham-XLightItalic.otf") format("opentype");
}

@font-face {
  font-family: "GothamBold";
  src: url("../fonts/GothamBold.ttf") format("truetype");
}

@font-face {
  font-family: "GothamBoldItalic";
  src: url("../fonts/GothamBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GothamBook";
  src: url("../fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: "GothamLight";
  src: url("../fonts/GothamLight.ttf") format("truetype");
}

@font-face {
  font-family: "GothamLightItalic";
  src: url("../fonts/GothamLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GothamMedium";
  src: url("../fonts/GothamMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa-Bold";
  src: url("../fonts/Nexa Bold.otf") format("opentype");
}

@font-face {
  font-family: "Nexa-Light";
  src: url("../fonts/Nexa Light.otf") format("opentype");
}

@font-face {
  font-family: "NexaBlack";
  src: url("../fonts/NexaBlack.otf") format("opentype");
}

@font-face {
  font-family: "NexaBlackItalic";
  src: url("../fonts/NexaBlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "NexaBlackItalic";
  src: url("../fonts/NexaBlackItalic.otf") format("opentype");
}



@font-face {
  font-family: "NexaRegular";
  src: url("../fonts/NexaRegular.otf") format("opentype");
}
