.mobile-nav-shower-icon {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  background-color: #4d00aa;
  padding: 1rem;
  border-radius: 200px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    display: none;
  }
}

.custom-mobile-navbar {
  @media (min-width: 800px) {
    display: none;
  }
}

.drop-down-content-mobile-new {
  position: absolute;
  bottom: 30px;
  left: 0%;
  width: 100vw;
  transition: 0.2s ease-in-out;
  z-index: 9999999;
  .dpp-inside {
    padding: 2rem 1rem;
    width: 80%;
    margin: 0 auto;
    border-radius: 8px;
    min-height: 200px;
    max-height: fit-content;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mob-elements {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: karla;
    margin: 1rem 0;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.062);
    p {
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }
}

.mobile-navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: linear-gradient(180deg, #5d00d2 0%, #530094 100%);
  z-index: 9999999;
  transition: 0.4s all;
  @media (min-width: 800px) {
    display: none;
  }

  .mobile-navbar-inside {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    .top-cross-mobile-navbar {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .main-navbar-mobile-tablet {
      width: 100%;
      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        li {
          margin: 1.5rem 1rem;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            h4 {
              color: #fff;
              font-family: GothamBook;
              font-size: 1.6rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-left: 1.5rem;
            }
          }
        }

        .dropdown {
          display: none;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          li {
            margin: 1.5rem 2rem;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              h4 {
                color: #fff;
                font-family: GothamBook;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
