.search-page-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .search-page-inside {
    width: 90%;
    height: 90%;
    border-radius: 0.625rem;
    background-color: white;

    .search-page-inside-div-projcets {
      width: 80%;
      margin: 0 auto;
      height: 80%;
      overflow-y: auto;
    }
    .search-page-input-element {
      width: 80%;
      margin: 2rem auto;
      .input-button-attached {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          margin: 0;
          margin-bottom: 1rem;
          width: 100%;
          height: 6vh;
          padding: 0rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 5px 0px 0px 5px;
          &::placeholder {
            color: #8f8585;
          }
          &:focus {
            outline: 0px;
          }
        }
        button {
          width: 30%;
          border-radius: 0px 5px 5px 0px;
          color: #ffffff;
          margin-bottom: 1rem;
          height: 6vh;
          background-color: #5d00d2;
          border: 0px;
          padding: 0rem 0.8rem;
        }
      }

      .advanced-ai-search {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        margin-left: 1rem;
        margin-top: 0.5rem;
        a {
          padding: 0;
          margin: 0;
          color: #5d00d2;
          margin-left: 0.25rem;
          font-size: 1rem;
        }
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}
