.notification-box {
  position: absolute;
  top: 10;
  right: 0;
  min-width: 30vw;
  max-width: fit-content;
  max-height: 30vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  .top-notification-close {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: right;
    padding: 0.25rem 0;
  }
}
