.project-component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-component-inside {
    width: 96%;
    height: 96%;
    background-color: white;
    border-radius: 0.625rem;
  }

  .project-c-header {
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
    // min-height: 20%;
    // height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 800px) {
      width: 80%;
    }

    .advanced-ai-search {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-left: 1rem;
      margin-top: 0.5rem;
      a {
        padding: 0;
        margin: 0;
        color: #5d00d2;
        margin-left: 0.25rem;
        font-size: 1rem;
      }
    }

    .project-c-header-left {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button.setting-btn {
        @media (max-width: 800px) {
          margin-top: 1rem;
        }
        &:nth-child(1) {
          margin-right: 1rem;
          background: #5d00d2;
          position: relative;
          &:hover {
            .drop-down-content {
              display: flex;
              align-content: center;
              justify-content: center;
            }
          }
        }
        &:nth-child(2) {
          font-size: 0.5rem;
        }
        border-radius: 0.3125rem;
        background: #5d00d2;
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 2rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transition: 0.2s all;
        &:hover {
        }
      }
      .drop-down-content {
        display: none;
        position: absolute;
        top: 3rem;
        left: 2rem;
        width: 15vw;
        z-index: 888888;
        .middle-navbar-container {
          width: 90%;
          height: 100%;
          margin: 0 auto;
          background: #ffffff;
          box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);

          h3 {
            color: #fff;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 1.5rem;
          }
          .mnc-element {
            width: 100%;
            background: rgb(255, 255, 255);
            transition: 0.2s ease-in-out;
            border: 0px;
            &:hover {
              cursor: pointer;
              background: rgba(0, 0, 0, 0.123);
              padding: 0.5rem 0;
            }
            .mnc-element-inside {
              width: 80%;
              margin: 0 auto;
              display: flex;
              padding: 1rem 0;
              align-items: center;
              justify-content: space-between;
              .mnc-element-left {
                display: flex;
                align-items: center;

                justify-content: center;
                p {
                  color: #000;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 0 1rem;
                }
              }
              span {
                color: #777;
                font-family: Poppins;
                font-size: 0.825rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
      .p-c-h-l-t {
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-direction: column;
        // background-color: transparent;
        .p-c-h-t-l-inside {
          display: flex;
          flex-direction: column;
          background-color: transparent;
          h1 {
            color: #000;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left;
          }

          p {
            color: #000;
            font-family: Karla;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0.2rem 0;
          }
        }

        transition: 0.2s all;
        &:hover {
          cursor: pointer;
          padding: 0.25rem 0;
          // transform: scale(1.1);
          border-bottom: 1px solid gray;
        }
      }
    }
    input {
      margin-top: 1.5rem;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.625rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Karla;
      font-size: 1.15rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .project-c-header-right {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: end;

      @media (max-width: 800px) {
        width: 80%;
        justify-content: left;
      }

      svg {
        margin: 0 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .project-c-bottom {
    width: 90%;
    height: 80%;
    margin: 0 auto;
    overflow-y: auto;
    padding: 2rem 0;
    .sl-element {
      width: 100%;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 1px solid #ddd0d0;
      transition: 0.2s ease-in-out;
      background-color: transparent;

      &.white {
        background-color: white;
      }
      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.082);
      }
      .mnc-element-inside {
        width: 90%;
        margin: 0 auto;
        display: flex;
        padding: 1rem 0;
        align-items: center;
        justify-content: space-between;
        .mnc-element-left {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: left;
          @media (max-width: 800px) {
            width: 100%;
          }
          svg {
            width: 20%;
          }
          p {
            width: 80%;
            color: #000000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0 1rem;
            text-align: left;
          }
        }

        span {
          width: 25%;
          color: #777;
          font-family: Poppins;
          font-size: 0.825rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }
  }
}
