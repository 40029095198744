.admin-modal {
  width: 90%;
  height: 90%;
  // overflow-y: auto;
  background-color: white;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
