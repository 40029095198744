.inside-loader-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    color: #fff;
    margin-top: 1rem;
  }

  p {
    color: #fff;
    font-family: Karla;
  }

  .loader-two {
    position: relative;
    border: 24px solid #fff;
    border-radius: 50%;
    transform: rotate(45deg);
    animation: pieFill 3s linear infinite;
  }

  @keyframes pieFill {
    0%,
    19% {
      border-color: #fff #fff #fff #fff;
    }
    20%,
    39% {
      border-color: #5d00d2 #fff #fff #fff;
    }
    40%,
    59% {
      border-color: #5d00d2 #5d00d2 #fff #fff;
    }
    60%,
    79% {
      border-color: #5d00d2 #5d00d2 #5d00d2 #fff;
    }
    80%,
    100% {
      border-color: #5d00d2 #5d00d2 #5d00d2 #5d00d2;
    }
  }

  .loader-three {
    width: 48px;
    height: 48px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader-three::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: 20px;
    top: 31px;
    border: 10px solid transparent;
    border-right-color: #fff;
    transform: rotate(-40deg);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  // .loader-two {
  //   width: 70px;
  //   height: 35px;
  //   position: relative;
  //   overflow: hidden;
  // }
  // .loader-two:before {
  //   content: "";
  //   width: 70px;
  //   height: 70px;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   border: 5px solid #0000;
  //   border-color: #fff #fff #0000 #0000;
  //   border-radius: 50%;
  //   box-sizing: border-box;
  //   animation: rotate 3s ease-in-out infinite;
  //   transform: rotate(-200deg);
  // }
  @keyframes rotate {
    0% {
      border-width: 10px;
    }
    25% {
      border-width: 3px;
    }
    50% {
      transform: rotate(115deg);
      border-width: 10px;
    }
    75% {
      border-width: 3px;
    }
    100% {
      border-width: 10px;
    }
  }

  .loader-one {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #5d00d2;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
