.drawer-info {
  width: 100%;
  height: 100%;

  .drawer-info-inside {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    .drawer-info-top {
      width: 100%;

      .react-quill-editor {
        .quill > .ql-container > .ql-editor.ql-blank::before {
          font-size: 1rem;
          font-family: Poppins;
          font-style: normal;
          color: #8f8585;
          padding: 0.5rem 0;
          margin: 0;
        }

        .quill {
          border: 1px solid #d1c9c9;
          text-align: left;
          margin: 0rem 0;
          margin-bottom: 2rem;
          border-radius: 0.125rem;
          .ql-toolbar.ql-snow {
            border: 0px !important;
            // border-bottom: 1px solid #d1c9c9 !important;
            padding: 0px;
            background: #eaecec;
          }
          .ql-editor {
            padding: 0.5rem;
            height: 100px;
          }
        }
      }

      .topper-drawer-info {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 2rem 0;
        h2 {
          color: #000;
          font-family: Inter;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 0.5rem;
        }
      }
      form {
        width: 100%;
        .view-logs-inside {
          width: 100%;
          margin: 2rem auto;

          .header-logs-inside,
          .content-logs-inside {
            width: 100%;
            display: flex;

            h2 {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              border: 0px;
              border-bottom: 1px solid rgb(226, 226, 226);
              color: #000;
              font-family: Inter;
              font-size: 0.75rem;
              padding: 1rem 0;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            h2.log-message-h2 {
              padding: 0.2rem;
              overflow-y: auto;
              width: 50%;
              font-size: 0.75rem;
            }
          }
          .main {
            h2 {
              font-size: 1rem;
              border: 1px solid rgb(226, 226, 226);
            }
          }
        }

        .label-input {
          label {
            color: #818181;
            font-size: 1rem;
          }
          input {
            margin: 0;
            margin-bottom: 1rem;
            width: 100%;
            padding: 1rem 0.8rem;
            border-radius: 0.125rem;
            border: 1px solid #d1c9c9;
            color: #000000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &::placeholder {
              color: #8f8585;
            }
            &:focus {
              outline: 0px;
            }
          }
        }
        label {
        }
        input {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &::placeholder {
            color: #8f8585;
          }
          &:focus {
            outline: 0px;
          }
        }
        select {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &::placeholder {
            color: #8f8585;
          }
          &:focus {
            outline: 0px;
          }
        }
        option {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover {
            color: black;
          }
        }
        button {
          outline: 0px;
          border: 0px;
          width: 100%;
          background-color: #6200d2;
          color: #ffffff;
          font-size: 1rem;
          font-family: Poppins;
          margin: 1.5rem 0;
          padding: 1rem 0;
          border-radius: 0.625rem;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          svg {
            margin-right: 0.5rem;
          }
          &:hover {
            cursor: pointer;
            padding: 1.5rem;
          }
        }
        .delete {
          background-color: #d2003f;
        }
        .export,
        .audit {
          background-color: transparent;
          border: 1px solid #6200d2;
          color: #6200d2;
        }
      }
    }
  }
}
