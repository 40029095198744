.login-container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #5d00d2 0%, #1b0094 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  .login-main {
    width: fit-content;
    height: fit-content;
    background-color: #ffffff;
    width: 35%;
    padding: 2.5rem;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
      width: 90%;
    }

    @media (max-width: 600px) {
      width: 90%;
    }
    img.logo-login {
      width: 8rem;
      padding: 2rem 0;
    }

    h1 {
      color: #484848;
      text-align: center;
      font-family: Poppins;
      font-size: 1.5rem;
      padding-bottom: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0rem;
    }
    p {
      width: 50%;
      color: #333;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    form {
      width: 80%;
      margin: 0 auto;

      @media (max-width: 800px) {
        width: 90%;
      }

      input {
        margin: 0.8rem 0;
        width: 100%;
        padding: 1rem 0.8rem;
        border-radius: 0.125rem;
        border: 1px solid #d1c9c9;
        color: #000000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        border-radius: 0.625rem;
        line-height: normal;
        &::placeholder {
          color: #8f8585;
        }
        &:focus {
          outline: 0px;
        }
      }

      button {
        outline: 0px;
        border: 0px;
        width: 100%;
        background-color: #1500d2;
        color: #ffffff;
        font-family: Poppins;
        margin: 1rem 0;
        padding: 1rem 0;
        border-radius: 0.625rem;

        &:hover {
          cursor: pointer;
        }
      }
    }

    h4 {
      width: 100%;
      text-align: center;
      border-top: 1px solid rgb(224, 224, 224);
      margin-top: 2rem;
      line-height: 0.1em;
      margin: 10px 0 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      span {
        background: #fff;
        padding: 0 10px;
      }
    }
    .auth-containers {
      width: 80%;

      button {
        border: 0px;
        outline: 0px;
        width: fit-content;
        padding: 0.8rem 0;
        width: 100%;

        background: var(--white, #fff);
        color: #2a254b;
        /* Body/Medium */
        border: 1px solid rgb(175, 175, 175);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 2rem 0;
        transition: 0.2s all;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.625rem;
        img {
          width: 1.5rem;
          margin-right: 1rem;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.01);
        }
      }
    }
  }
}
