.org-content {
  width: 100vw;
  height: 100vh;
  background: #5d00d2;
  display: flex;
  align-items: center;
  justify-content: center;
  .org-div-container {
    width: 40%;
    height: 80%;
    background-color: #fff;
    box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0.625rem;

    .top-org-div-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }

      p {
        color: #393939;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
      }
      button {
        width: 50%;
        padding: 1rem 0;
        background-color: #6200d2;
        color: white;
        margin: 1rem 0;
      }
    }
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }
  label,
  input {
    cursor: pointer;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    line-height: 1.3;
    color: #1f2949;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
    font-weight: 400;
  }
  img {
    max-width: 100%;
    display: block;
    vertical-align: middle;
  }
  .container {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-text-wrapper {
    margin: 20px 0 30px 0;
  }
  .top-text-wrapper h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .top-text-wrapper code {
    font-size: 0.85em;
    background: linear-gradient(90deg, #fce3ec, #ffe8cc);
    color: #ff2200;
    padding: 0.1rem 0.3rem 0.2rem;
    border-radius: 0.2rem;
  }
  .tab-section-wrapper {
    padding: 30px 0;
  }

  .grid-wrapper {
    display: grid;
    grid-gap: 30px;
    place-items: center;
    place-content: center;
  }
  .grid-col-auto {
    // grid-template-columns: repeat(auto-fill, minmax(280px, 0.1fr));
    grid-auto-flow: column;
    grid-template-rows: auto;
  }

  /* ******************* Main Styeles : Radio Card */

  $primary-color: #3057d5;
  $transition: 200ms linear;

  label.radio-card {
    cursor: pointer;

    .card-content-wrapper {
      background: #fff;
      border-radius: 5px;
      max-width: 280px;
      min-height: 330px;
      padding: 15px;
      display: grid;
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
      transition: $transition;
    }

    .check-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: $transition;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center center;
        transform: scale(1.6);
        transition: $transition;
        opacity: 0;
      }
    }

    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:checked {
        + .card-content-wrapper {
          box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5),
            0 0 0 2px $primary-color;

          .check-icon {
            background: $primary-color;
            border-color: $primary-color;
            transform: scale(1.2);
            &:before {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }

      &:focus {
        + .card-content-wrapper {
          .check-icon {
            box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
            border-color: #3056d5;
          }
        }
      }
    }

    .card-content {
      img {
        margin-bottom: 10px;
      }
      h4 {
        font-size: 16px;
        letter-spacing: -0.24px;
        text-align: center;
        color: #1f2949;
        margin-bottom: 10px;
      }

      h5 {
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        color: #686d73;
      }
    }
  }
}

#radio-card-1,
#radio-card-2 {
  display: none;
}

.create-org-modal-inside-new {
  width: 100%;
  min-height: 50%;
  height: fit-content;
  background-color: white;
  border-radius: 0.625rem;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  h1 {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2rem;
  }
  p {
    width: 50%;
    color: #333;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .form-inside-org {
    width: 100%;
    margin: 0 auto;
  }
  form {
    width: 60%;
    margin: 0 auto;
    padding: 2rem 0;

    .label-input {
      label {
        color: #818181;
        font-size: 1rem;
      }
      input {
        margin: 0;
        margin-bottom: 1rem;
        width: 100%;
        padding: 1rem 0.8rem;
        border-radius: 0.125rem;
        border: 1px solid #d1c9c9;
        color: #000000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:hover {
          cursor: wait;
        }
        &::placeholder {
          color: #8f8585;
        }
        &:focus {
          outline: 0px;
        }
      }
    }
    label {
    }
    input {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    textarea {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    select {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    option {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:hover {
        color: black;
      }
    }
    button {
      outline: 0px;
      border: 0px;
      width: 100%;
      background-color: #6200d2;
      color: #ffffff;
      font-family: Poppins;
      margin: 1rem 0;
      padding: 1rem 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.create-org-modal-inside {
  width: 50%;
  min-height: 50%;
  height: fit-content;
  background-color: white;
  border-radius: 0.625rem;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  h1 {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2rem;
  }
  p {
    width: 50%;
    color: #333;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .form-inside-org {
    width: 80%;
    margin: 0 auto;
  }
  form {
    width: 60%;
    margin: 0 auto;
    padding: 2rem 0;

    .label-input {
      label {
        color: #818181;
        font-size: 1rem;
      }
      input {
        margin: 0;
        margin-bottom: 1rem;
        width: 100%;
        padding: 1rem 0.8rem;
        border-radius: 0.125rem;
        border: 1px solid #d1c9c9;
        color: #000000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:hover {
          cursor: wait;
        }
        &::placeholder {
          color: #8f8585;
        }
        &:focus {
          outline: 0px;
        }
      }
    }
    label {
    }
    input {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    textarea {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    select {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #8f8585;
      }
      &:focus {
        outline: 0px;
      }
    }
    option {
      margin: 0.8rem 0;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.125rem;
      border: 1px solid #d1c9c9;
      color: #000000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:hover {
        color: black;
      }
    }
    button {
      outline: 0px;
      border: 0px;
      width: 100%;
      background-color: #6200d2;
      color: #ffffff;
      font-family: Poppins;
      margin: 1rem 0;
      padding: 1rem 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
