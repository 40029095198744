.reports-and-dashboards {
  width: 79vw;
  height: 100%;
  float: right;
  background-color: white;
  @media (max-width: 800px) {
    width: 100vw;
  }
}

.view-report-modal {
  width: 50%;
  min-height: 90%;
  height: fit-content;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.65rem;
  padding-bottom: 5rem;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #93affd60;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #861cff;
  }
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;

    button {
      background-color: transparent;
      border: 0px;
      position: sticky;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .view-report-details-tp {
    width: 85%;
    margin: 0 auto;
    border-bottom: 1px solid #b6b6b669;
    padding-bottom: 0.25rem;
    h1 {
      color: #000;
      font-family: GothamBook;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 1rem 0;
    }
    p {
      color: #867979;
      font-family: Karla;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .view-report-charts {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
  }

  .view-report-data-grid {
    width: 90%;
    margin: 0 auto;
  }
}

.report-modal-container {
  width: 50%;
  min-height: 90%;
  height: fit-content;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.65rem;
  padding-bottom: 5rem;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report-main-container {
    width: 90%;
    margin: 0 auto;

    .reports-main-container-inside {
      width: 90%;
      margin: 0 auto;
    }
    .reports-main-container-preview {
      width: 50%;
      padding: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.main-content-inside-reports-main {
  width: 80%;
  margin: 0 auto;
}

.dash-modal-container {
  width: 50%;
  min-height: 90%;
  height: fit-content;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.65rem;
  padding-bottom: 5rem;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .dash-main-container {
    width: 90%;
    margin: 0 auto;

    .dash-main-container-inside {
      width: 90%;
      margin: 0 auto;
    }
  }
}

.dash-modal-view {
  width: 70%;
}

.dash-home {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 1rem 0;
  .dash-inside-home-header {
    width: 80%;
    height: 20%;
    margin: 0 auto;
    margin-top: 2rem;
    .dash-home-header-top {
      h1 {
        font-family: Poppins;
        font-size: 2rem;
      }
      p {
        font-family: karla;
        font-size: 1rem;
      }
    }

    .dash-home-header-bottom {
      padding-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }

  .dash-home-main-content {
    width: 80%;
    margin: 0 auto;
  }
}
