.spreadsheet-modal {
  width: 80%;
  height: 80%;
  background-color: white;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s all;
    .top-modal-content {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      button {
        border-radius: 0.625rem;
        background: #5d00d2;
        color: white;
        font-size: 1rem;
        padding: 0.5rem 2rem;
        margin-left: 2rem;
        transition: 0.2s all;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .main-modal-spreadsheet {
    width: 100%;
    height: 90%;
    z-index: 9999999;
    .luckysheet-cell-input {
      z-index: 999999999999999;
    }
  }
}

// #spreadsheet-opener {
//   width: 80%;
//   margin: 0 auto;
//   height: 50vh;
//   z-index: 9999999;
//   border: 1px solid #5d00d2;
// }

#spreadsheet-opener {
  // width: 80%;
  // margin: 0 auto;
  // height: 50vh;
  // border: 1px solid #5d00d2;
  background-color: transparent;
  border: 0px;
  width: 20%;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  color: #188038;
  font-family: Gotham-Black;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: underline;
  line-height: normal;

  transition: 0.2s all;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}
