.main-loader-scss {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  .main-loader-scss-inside {
  }
}
