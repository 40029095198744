.project-settings {
  width: 80vw;
  height: 90vh;
  background-color: #fff;
  border-radius: 0.625rem;
  .top-modal {
    width: 90%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .project-main {
    width: 100%;
    height: 100%;
    .project-main-inside {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      .project-s-header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 800px) {
          flex-direction: column;
        }

        .project-s-right {
          h1 {
            color: #000;
            font-family: Inter;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          p {
            color: #000;
            font-family: Karla;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 0.5rem;
          }
        }

        .project-s-left {
          @media (max-width: 800px) {
          }
          button {
            color: #5d00d2;
            padding: 0.8rem 2rem;
            font-family: Karla;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: 1px solid #5d00d2;
            border-radius: 0.25rem;
            // background: #5d00d2;
            transition: 0.2s all;
            &:not(:last-child) {
              margin-right: 1rem;
            }
            &:hover {
              background-color: #5d00d2;
              color: white;
            }
          }

          button.active {
            background-color: #5d00d2;
            color: white;
          }
        }
      }

      .project-main-body-s {
        width: 100%;
        height: 70%;
        margin-top: 1rem;
        border-radius: 0.625rem;
        border: 1px solid #dadada;

        .project-main-body-s-inside {
          width: 80%;
          height: 100%;
          margin: 0 auto;
          .invite-team-member-s {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            h1 {
              color: #000;
              font-family: Poppins;
              font-size: 1.15rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }
            input {
              width: 70%;
              border: 1px solid #eedede;
              padding: 0.8rem 1rem;
              font-family: Poppins;
              font-size: 1rem;

              &:focus {
                border: 0px;
              }
            }
            button {
              width: 20%;
              background: #5d00d2;
              padding: 0.8rem 0.5rem;
              font-size: 1rem;
              font-family: Poppins;
              border: 0px;
              color: white;
              margin: 1rem 0;
            }
          }
          .invite-team-member-s-all {
            width: 100%;
            height: 50%;
            display: flex;
            flex-direction: column;
            h1 {
              color: #000;
              font-family: Poppins;
              font-size: 1.15rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }

            .team-members-s-all {
              width: 100%;
              height: 100%;
              overflow: auto;
              &::-webkit-scrollbar {
                width: 5px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #ffffff;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #888;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
              .invite-team-right {
                width: 100%;
                height: 100%;
                .team-already-right {
                  width: 100%;
                  height: 100%;
                  .profile-header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0.5rem 0;
                    padding: 1rem;
                    border: 1px solid #eedede;
                    .profile-header-left {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      img {
                        width: 3rem;
                        border-radius: 15.625rem;
                      }
                      .phl-content {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        margin-left: 1rem;
                        h1 {
                          margin: 0;
                          padding: 0.25rem;
                        }
                        a {
                          margin: 0;
                          font-size: 1rem;
                          font-family: karla;
                        }
                      }
                    }
                    .profile-header-right {
                      a {
                        background: #ffffff;
                        padding: 0.5rem 1rem;
                        font-size: 1rem;
                        font-family: Poppins;
                        border: 0px;
                        color: rgb(0, 0, 0);
                        border: 1px solid black;
                        margin: 1rem 0;
                        margin-right: 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .view-logs-inside {
        width: 80%;
        height: 90%;
        overflow: auto;
        margin: 2rem auto;

        .header-logs-inside,
        .content-logs-inside {
          width: 100%;
          display: flex;

          h2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            border: 0px;
            border-bottom: 1px solid rgb(226, 226, 226);
            color: #000;
            font-family: Inter;
            font-size: 1rem;
            padding: 1rem 0;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          h2.log-message-h2 {
            width: 50%;
            font-size: 0.75rem;
          }
        }
        .main {
          h2 {
            font-size: 1.25rem;
            border: 1px solid rgb(226, 226, 226);
          }
        }
      }

      .invite-team {
        width: 80%;
        margin: 2rem auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        border: 1px solid rgb(214, 214, 214);
        .invite-team-left {
          width: 100%;
          h2 {
            color: var(--gray-700, #344054);

            /* Text sm/Semibold */
            font-family: Inter;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem; /* 142.857% */
          }
          p {
            color: var(--gray-600, #475467);

            /* Text sm/Regular */
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem; /* 142.857% */
            border: 0px;
          }
        }

        .invite-team-right {
          width: 100%;
          height: 15vh;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          h2 {
            font-size: 1rem;
            color: rgb(255, 41, 41);
          }
          form {
            width: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
            input {
              width: 100%;
              padding: 1rem 0.8rem;
              border-radius: 0.125rem;
              border: 1px solid #d1c9c9;
              color: #000000;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              &::placeholder {
                color: #8f8585;
              }
              &:focus {
                outline: 0px;
              }
            }
            select {
              margin: 0.8rem 0;
              width: 100%;
              padding: 1rem 0.8rem;
              border-radius: 0.125rem;
              border: 1px solid #d1c9c9;
              color: #000000;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              &::placeholder {
                color: #8f8585;
              }
              &:focus {
                outline: 0px;
              }
            }
            option {
              margin: 0.8rem 0;
              width: 100%;
              padding: 1rem 0.8rem;
              border-radius: 0.125rem;
              border: 1px solid #d1c9c9;
              color: #000000;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              &:hover {
                color: black;
              }
            }
            button {
              outline: 0px;
              border: 0px;
              width: 40%;
              background-color: #6200d2;
              color: #ffffff;
              font-family: Poppins;
              margin: 1rem 0;
              padding: 1rem 0;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .profile-header {
            width: 100%;
            height: fit-content;
            // box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);
            padding: 1rem;
            display: flex;
            border-radius: 0.625rem;
            align-items: center;
            justify-content: space-between;
            transition: 0.2s all;

            &:hover {
              cursor: pointer;
            }
            .profile-header-left {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 3rem;
                border-radius: 15.625rem;
              }
              .phl-content {
                margin-left: 1rem;

                h1 {
                  color: #000;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                a {
                  text-decoration: none;
                  color: #383838;
                  font-family: Poppins;
                  font-size: 0.8rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .profile-header-right {
              display: flex;
              align-items: center;
              justify-content: center;
              button {
                padding: 0.5rem 2rem;

                border: 1px solid #d4d4d4;
                color: #000;
                background-color: transparent;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.2s all;

                &:hover {
                  cursor: pointer;
                }
              }

              button.button-user-type {
                color: black;
                background-color: #ffffff;
                &:hover {
                  transform: scale(1);
                }
              }
            }
          }
        }
        .invte {
          height: fit-content;
        }
      }
    }
  }
}
