.main-account-settings {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #31006e 0%, #1b0094 100%);
  .first-time-modal {
    width: 40%;
    height: 80%;
    background-color: #fff;
    box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0.625rem;
    .first-time-modal-inside {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        color: #000;
        font-family: Inter;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      form {
        width: 80%;
        margin: 0 auto;

        .label-input {
          label {
            color: #818181;
            font-size: 1rem;
          }
          input {
            margin: 0;
            margin-bottom: 1rem;
            width: 100%;
            padding: 1rem 0.8rem;
            border-radius: 0.125rem;
            border: 1px solid #d1c9c9;
            color: #000000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &::placeholder {
              color: #8f8585;
            }
            &:focus {
              outline: 0px;
            }
          }
        }
        label {
        }
        input {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &::placeholder {
            color: #8f8585;
          }
          &:focus {
            outline: 0px;
          }
        }
        select {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &::placeholder {
            color: #8f8585;
          }
          &:focus {
            outline: 0px;
          }
        }
        option {
          margin: 0.8rem 0;
          width: 100%;
          padding: 1rem 0.8rem;
          border-radius: 0.125rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover {
            color: black;
          }
        }
        button {
          outline: 0px;
          border: 0px;
          width: 100%;
          background-color: #6200d2;
          color: #ffffff;
          font-family: Poppins;
          margin: 1rem 0;
          padding: 1rem 0;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
