.footer-main {
  width: 94%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    text-align: center;
    font-size: 1rem;
    font-family: Poppins;
    color: rgba(0, 0, 0, 0.767);
    span {
      display: block;
      padding-top: 5px;
      a {
        font-family: karla;
        color: #5d00d2;
      }
    }
  }
}
