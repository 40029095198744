.sample-modal-container-main {
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 0.625rem;
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .sample-modal-container-inside {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    overflow-y: auto;
    .sample-modal-container-top-div {
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sample-modal-top-left {
        .sample-modal-top-left-top {
          display: flex;
          align-items: center;
          justify-content: center;
          h1 {
            color: #000;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        p {
          color: #595959;
          font-family: Karla;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.5rem 0;
        }
      }
      .sample-modal-top-right {
        button {
          border-radius: 0.3125rem;
          border: 0px;
          padding: 1rem 3rem;
          background: #5d00d2;
          color: #fff;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:nth-child(1) {
            margin-right: 1rem;
          }
        }
      }
    }
    .sample-modal-details-container {
      width: 80%;
      height: 70%;
      margin: 1rem auto;

      .top-level-sample-details {
        width: 100%;
        border: 1px solid rgb(197, 197, 197);
        padding: 1rem;
        border-radius: 0.625rem;
        h4 {
          font-size: 1rem;
        }
        .top-level-sample-details-inside-bottom {
          span {
            font-family: karla;
          }
        }
      }
    }
    .sample-modal-main-container {
      width: 50%;
      height: 70%;
      margin: 1rem auto;

      .view-logs-inside {
        width: 100%;
        height: 100%;

        overflow-y: auto;
        padding: 1rem 0;
        margin: 0 auto;
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .header-logs-inside,
        .content-logs-inside {
          width: 100%;
          display: flex;

          h2 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            border: 0px;
            border: 1px solid rgb(226, 226, 226);
            border-right: 0;
            color: #000;
            font-family: Inter;
            font-size: 1rem;
            padding: 1rem 0;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0.5rem 0;
          }

          h2.log-message-h2 {
            padding: 0.2rem;
            width: 50%;
            font-size: 1rem;
          }
        }
        .main {
          h2 {
            font-size: 1rem;
            border: 1px solid rgb(226, 226, 226);
          }
        }
      }
    }
  }
}
