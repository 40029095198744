.project-component-samples-new {
}

.p-c-s-i-t {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-family: Poppins;
    font-size: 2rem;
    padding-top: 1rem;
  }
  button {
    margin-right: 1rem;
  }
}

.p-c-s-charts {
  width: 90%;
  margin: 0 auto;
  height: 90%;
  padding-bottom: 5rem;
  .area-chart {
    width: 100%;
    height: 50%;
  }
  .samples-below-chart-div {
    margin-top: 2rem;
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-family: "Inter", sans-serif; /* Change this to your desired font family */
}

.loader-div-main-stellr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inside-stats {
  width: 70%;
  min-height: 80%;
  height: fit-content;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.65rem;
  padding-bottom: 5rem;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .top-modal {
    width: 80%;
    padding: 1rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: 0.2s all;
    button {
      background-color: transparent;
      border: 0px;
    }
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.custom-sample-record {
  width: 100%;
  height: 100%;

  .top-header-custom-sample {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-header-left-custom {
      width: 50%;
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .main-div-custom-template {
    padding: 2rem 0;
  }
}
