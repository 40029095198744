.main-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.navbar {
  width: 6%;
  height: 100%;
  background: linear-gradient(180deg, #5d00d2 0%, #530094 100%);
  // background: #6002bf;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 800px) {
    width: 0%;
  }

  .top-navbar {
    width: 100%;
    height: 75%;
    ul {
      width: 100%;
      height: 100%;
      li {
        width: 100%;
        height: fit-content;
        padding: 1rem 0;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: white;
          text-align: center;
          font-size: 0.8rem;
          padding: 0 0.1rem;
          svg {
            margin-bottom: 0.25rem;
          }
        }
        transition: 0.2s all;
        &:hover {
          cursor: pointer;
          background-color: #00000038;
        }
      }
      li.create {
        position: relative;
        width: 100%;
        &:hover {
          .drop-down-content {
            display: flex;
            align-content: center;
            justify-content: center;
          }
        }
        .drop-down-content {
          display: none;
          position: absolute;
          top: 2rem;
          left: 6rem;
          width: 15vw;
          z-index: 888888;
          .middle-navbar-container {
            width: 90%;
            height: 100%;
            margin: 0 auto;
            background: #ffffff;
            box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);

            h3 {
              color: #fff;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 1.5rem;
            }
            .mnc-element {
              width: 100%;
              background: rgb(255, 255, 255);
              transition: 0.2s ease-in-out;
              border: 0px;
              &:hover {
                cursor: pointer;
                background: rgba(0, 0, 0, 0.123);
                padding: 0.5rem 0;
              }
              .mnc-element-inside {
                width: 80%;
                margin: 0 auto;
                display: flex;
                padding: 1rem 0;
                align-items: center;
                justify-content: space-between;
                .mnc-element-left {
                  display: flex;
                  align-items: center;

                  justify-content: center;
                  p {
                    color: #000;
                    font-family: Poppins;
                    font-size: 0.9rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 0 1rem;
                    text-align: left;
                  }
                }
                span {
                  color: #777;
                  font-family: Poppins;
                  font-size: 0.825rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
  .middle-navbar {
    width: 100%;
    min-height: 50%;
    height: fit-content;
    padding: 1rem 0;
    .middle-navbar-container {
      width: 90%;
      height: 100%;
      margin: 0.5rem auto;
      border-radius: 0.625rem;

      background: rgba(0, 0, 0, 0.54);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &.active {
        .mnc-element {
          display: block;
        }
      }
      h3 {
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #24004b;
        border-bottom: 1px solid rgba(255, 255, 255, 0.221);
        border-radius: 1rem;
      }
      .mnc-element {
        width: 100%;
        background: rgba(0, 0, 0, 0.12);
        transition: 0.2s ease-in-out;
        border: 0px;
        padding: 0.2rem 0;
        display: none;
        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.26);
        }
        .mnc-element-inside {
          width: 80%;
          margin: 0 auto;
          display: flex;
          padding: 1rem 0;
          align-items: center;
          justify-content: space-between;
          .mnc-element-left {
            display: flex;
            align-items: center;

            justify-content: center;
            p {
              color: #fff;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 0 1rem;
            }
          }
          span {
            color: #777;
            font-family: Poppins;
            font-size: 0.825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          a {
            text-decoration: none;
            color: #209e00;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
  .bottom-navbar {
    width: 100%;
    height: 25%;
    padding-bottom: 1rem;
    z-index: 55;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      li {
        width: 100%;
        height: fit-content;
        padding: 1rem 0;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: white;
          text-align: center;
          font-size: 0.8rem;
          svg {
            margin-bottom: 0.25rem;
          }
        }
        transition: 0.2s all;
        &:hover {
          background-color: #4d00aa;

          .drop-down-content {
            display: flex;
            align-content: center;
            justify-content: center;
          }
        }
        .drop-down-content {
          display: none;
          position: absolute;
          bottom: 2rem;
          left: 6rem;
          width: 18vw;
          z-index: 888888;
          .middle-navbar-container {
            width: 90%;
            height: 100%;
            margin: 0 auto;
            background: #ffffff;
            box-shadow: 2px 6px 34px 0px rgba(0, 0, 0, 0.09);

            h3 {
              color: #fff;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 1.5rem;
            }
            .mnc-element {
              width: 100%;
              background: rgb(255, 255, 255);
              transition: 0.2s ease-in-out;
              border: 0px;
              &:hover {
                cursor: pointer;
                background: rgba(0, 0, 0, 0.123);
                padding: 0.5rem 0;
              }
              .mnc-element-inside {
                width: 80%;
                margin: 0 auto;
                display: flex;
                padding: 1rem 0;
                align-items: center;
                justify-content: space-between;
                .mnc-element-left {
                  display: flex;
                  align-items: center;

                  justify-content: center;
                  p {
                    color: #000;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 0 1rem;
                    text-align: left;
                  }
                }
                span {
                  color: #777;
                  font-family: Poppins;
                  font-size: 0.825rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-content {
  width: 94%;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 800px) {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .main-content-in-editor {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .structure-left {
    width: 20%;
    height: 100%;
    transition: 0.2s ease-in-out;
    background: #ffffff;
    // #4d00aa
    .sl-inside {
      width: 100%;
      height: 100%;
      .sl-inside-top-back {
        width: 90%;
        height: 4%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: start;
        svg {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .sl-header {
        width: 90%;
        min-height: 13%;
        height: fit-content;
        margin: 0 auto;
        .sl-header-top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 0;
          p {
            color: #000000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &:hover {
              cursor: pointer;
            }
          }
          .sl-header-top-svgs {
            display: flex;
          }

          svg {
            &:nth-child(1) {
              margin-right: 1rem;
            }
            transition: 0.2s all;
            &:hover {
              cursor: pointer;
            }
          }
        }

        input {
          margin: 0rem 0;
          width: 100%;
          padding: 0.5rem 0.8rem;
          border-radius: 0.25rem;
          border: 1px solid #d1c9c9;
          color: #000000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &:focus {
            outline: 0px;
          }
        }

        .sl-header-select {
          width: 100%;
          padding: 0.5rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .sl-elements-content {
        width: 100%;
        height: 79%;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #4d00aa;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #ffffff;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #ffffff;
        }
      }
      .sl-element {
        width: 100%;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px solid #00000011;
        transition: 0.2s ease-in-out;
        background-color: transparent;
        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.082);
        }
        .mnc-element-inside {
          width: 90%;
          margin: 0 auto;
          display: flex;
          padding: 1rem 0;
          align-items: flex-start;
          flex-direction: column;
          .mnc-element-left {
            width: 100%;
            display: flex;
            align-items: center;
            svg {
              width: 10%;
            }
            .entity-name-right {
              width: 90%;

              p {
                width: 100%;
                color: #000000;
                font-family: GothamBook;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 0 1rem;
                text-align: left;
              }
            }
          }
          span {
            width: 100%;
            display: flex;
            justify-content: right;
            color: #c4c4c4;
            font-family: Poppins;
            font-size: 0.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0.5rem;
          }
        }
      }
    }
  }
  .main-structure {
    width: 80%;
    height: 100%;
    background-color: #ececec;
    transition: 0.2s ease-in-out;
    .main-inside {
      width: 95%;
      height: 100%;
      margin: 0 auto;

      &.calendar-main-inside {
        width: 100%;
      }
    }
  }
}

.middlenav-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1rem 0;
  }
  a {
    color: #5d00d2;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
